import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ApiNames from "../constants/ApiNames";
import { encode as base64_encode } from "base-64";
import { fetchGet } from "../utils/FetchApi";
import Awareness from "../components/Awareness";
import tagManagerEvents from "../utils/GoogleTagManager";
import TagManager from "react-gtm-module";

let adNetwork = "";
const AwarenessScreen = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const utm_source = searchParams.get("utm_source")
    ? searchParams.get("utm_source")
    : "";
  useEffect(() => {
    if (props.token) {
      checkUtms();
      getFlow();
    }
  }, [props.token]);
  useEffect(() => {
    setTimeout(() => {
      gtmInitialization();
    }, 1000);
  }, []);
  const gtmInitialization = () => {
    const tagManagerArgs = {
      gtmId: "GTM-5TH9GVH",
    };
    TagManager.initialize(tagManagerArgs);
  };

  const checkUtms = async () => {
    try {
      let response = await fetchGet(ApiNames.utms, props.token);
      if (response.status === 200) {
        {
          response.response.map((e) => {
            if (utm_source === e.sf_utm) {
              adNetwork = e.ad_network;
            }
          });
        }
      }
      console.warn("adNEtwork", adNetwork);
    } catch (error) {
      console.log("check utm error", error);
    }
  };

  const getFlow = async () => {
    try {
      var endpoint = `${ApiNames.awarenessFlow}`;
      endpoint = endpoint + "?utm_source=" + utm_source;

      let response = await fetchGet(endpoint, props.token);

      if (response.status === 208) {
        if (response.response.message.toLowerCase() !== "already subscribed") {
          if (
            adNetwork.toLowerCase() === "tiktok" ||
            adNetwork.toLowerCase() === "tik tok"
          ) {
            tagManagerEvents("subscribe", "econTikTok");
          } else if (adNetwork.toLowerCase() === "google") {
            tagManagerEvents("subscribe", "econceptions");
          } else {
            tagManagerEvents("subscribe", utm_source);
          }
        }
        let url = window.location.origin;
        let encodedMsisdn = base64_encode(response.response.msisdn);
        let encodedSubId = base64_encode(response.response.subscribeId);

        url = `${url}/home?ndsism=${encodedMsisdn}&subid=${encodedSubId}`;
        if (url.includes("http:")) {
          url = url.replace("http", "https");
        }
        window.location.replace(url);
      } else {
        navigateToHome();
      }
    } catch (error) {
      navigateToHome();
    }
  };
  const navigateToHome = () => {
    let url = window.location.origin;
    url = `${url}/landing`;
    if (url.includes("http:")) {
      url = url.replace("http", "https");
    }
    window.location.replace(url);
  };

  return <Awareness />;
};

export default AwarenessScreen;
